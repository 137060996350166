document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new MmenuLight(
            document.querySelector("#menu")
        );

        const navigator = menu.navigation({
            theme: 'dark',
        });
        const drawer = menu.offcanvas(
            //options
            {
                node: document.querySelector("#menu-offcanvas"),
                // wird dasüberhaupt ausgewertet ?
                // es wird null übergeben anstatt node ?
                // s. https://github.com/FrDH/mmenu-light/blob/master/esm/core/index.js line 49 ->  this.drawer = new MmOffCanvasDrawer(null, position);
            }
        );

        document.querySelector('a[href="#menu"]')
            .addEventListener('click', (evnt) => {
                evnt.preventDefault();
                drawer.open();
            });
    }
);

